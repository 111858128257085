import { htmlAttributes, newRelicConstants, htmlSelectors } from '../constants';
import {
  getDimensions,
  getTestCoordinates,
  isElementOrChild,
  isTransparent,
  pushImpressionToAdobe, sendDefaultBannerImpression
} from './impressionHelpers';
import {
  persistViewConversionBeacon,
  triggerNewRelic,
  triggerNewRelicTimeLog
} from './helpers';
import { newRelicDataTestId } from '../constants/newRelicConstants';

export const isBeaconSent = (element) => {
  let beaconUrl = element.getAttribute(htmlAttributes.ONVIEW_BEACON_NUCLEUS)
    || element.getAttribute(htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS);
  if (window && window.SponsoredBannerImpressions && window.SponsoredBannerImpressions[beaconUrl]) {
    return true;
  }
  return false;
};

export const getPointsCovered = (localWindow, element) => {
  // Handles things cover the element or it being scrolled out of view
  const elementDimensions = getDimensions(localWindow, element);
  const elementCoordinates = getTestCoordinates(elementDimensions);
  // is the element on top at this coordinate the element?
  const elementAtPoints = elementCoordinates.map((coordinate) => {
    return document.elementFromPoint(coordinate.x, coordinate.y);
  });
  const pointsCovered = elementAtPoints.filter((elementAtPoint) => {
    return !isElementOrChild(elementAtPoint, element);
  });
  return pointsCovered.length;
};

export const isVisible = (localWindow, element) => {
  // checks for display:none anywhere in parent tree
  let result = element.offsetParent !== null;
  if (result) {
    // false if isTransparent
    result = !isTransparent(element);
  }

  if (result) {
    // At least 50% visible if no more than 2 points are covered (out of 4 corners + center)
    let pointsCovered;
    if (element.id === 'hp_sponsored__hero') { // hero only: check visibility of the wrapper but use attributes from child element
      pointsCovered = getPointsCovered(localWindow, document.querySelector(htmlSelectors.NUCLEUS_HERO_WRAPPER));
    } else {
      pointsCovered = getPointsCovered(localWindow, element);
    }

    result = pointsCovered <= 2;
  }

  return result;
};

function triggerAdTimeLogs(dataTestId, adLoadStartTime, bannerInitTime, innervateScriptTime, beaconAddTime, impressionFiredTime, adServer) {
  const timeLogMapping = {
    'sponsored-standard-banner': {
      adLoad: newRelicConstants.STANDARD_BANNER_AD_LOAD_TO_IMPRESSION,
      bannerInit: newRelicConstants.STANDARD_BANNER_AD_INIT_TO_IMPRESSION,
      innervateScript: newRelicConstants.STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
      beaconAdd: newRelicConstants.STANDARD_BANNER_AD_BEACON_ADDED_TO_IMPRESSION
    },
    'sponsored-skyscraper-banner': {
      adLoad: newRelicConstants.SKYSCRAPER_BANNER_AD_LOAD_TO_IMPRESSION,
      bannerInit: newRelicConstants.SKYSCRAPER_BANNER_AD_INIT_TO_IMPRESSION,
      innervateScript: newRelicConstants.SKYSCRAPER_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
      beaconAdd: newRelicConstants.SKYSCRAPER_BANNER_AD_BEACON_ADDED_TO_IMPRESSION
    },
    'sponsored-pip-banner': {
      adLoad: newRelicConstants.PIP_BANNER_AD_LOAD_TO_IMPRESSION,
      bannerInit: newRelicConstants.PIP_BANNER_AD_INIT_TO_IMPRESSION,
      innervateScript: newRelicConstants.PIP_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
      beaconAdd: newRelicConstants.PIP_BANNER_AD_BEACON_ADDED_TO_IMPRESSION
    },
    'sponsored-ingrid-banner': {
      adLoad: newRelicConstants.INGRID_BANNER_AD_LOAD_TO_IMPRESSION,
      bannerInit: newRelicConstants.INGRID_BANNER_AD_INIT_TO_IMPRESSION,
      innervateScript: newRelicConstants.INGRID_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
      beaconAdd: newRelicConstants.INGRID_BANNER_AD_BEACON_ADDED_TO_IMPRESSION
    },
    'sponsored-hero-banner': {
      adLoad: newRelicConstants.HERO_BANNER_AD_LOAD_TO_IMPRESSION,
      bannerInit: newRelicConstants.HERO_BANNER_AD_INIT_TO_IMPRESSION,
      innervateScript: newRelicConstants.HERO_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION,
      beaconAdd: newRelicConstants.HERO_BANNER_AD_BEACON_ADDED_TO_IMPRESSION
    }
  };

  const timeLogs = timeLogMapping[dataTestId];
  if (timeLogs) {
    if (adLoadStartTime) {
      triggerNewRelicTimeLog(timeLogs.adLoad, adLoadStartTime, impressionFiredTime, adServer);
    }
    if (bannerInitTime) {
      triggerNewRelicTimeLog(timeLogs.bannerInit, bannerInitTime, impressionFiredTime, adServer);
    }
    if (innervateScriptTime) {
      triggerNewRelicTimeLog(timeLogs.innervateScript, innervateScriptTime, impressionFiredTime, adServer);
    }
    if (beaconAddTime) {
      triggerNewRelicTimeLog(timeLogs.beaconAdd, beaconAddTime, impressionFiredTime, adServer);
    }
  }
}

function handleImpression(element, onviewbeacon, dataTestId) {
  const adLoadStartTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP))).getTime();
  const bannerInitTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP))).getTime();
  const innervateScriptTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP))).getTime();
  const beaconAddTime = new Date(Number(element.getAttribute(htmlAttributes.DATA_BEACON_ADD_TIME))).getTime();
  const slotId = element.getAttribute(htmlAttributes.DATA_SLOT_ID);
  const adServer = element.getAttribute(htmlAttributes.DATA_ADSERVER);
  const viewConversionBeacon = element.getAttribute(htmlAttributes.DATA_ON_VIEW_CONVERSION_BEACON);
  const mcvisID = element.getAttribute(htmlAttributes.DATA_MCVISID);

  const existingImg = document.querySelector(`img[src="https:${onviewbeacon}"]`);
  if (!existingImg) {
    let beaconStr = `<img class="onviewbeacon" style="display:none;" src="https:${onviewbeacon}">`;
    document.body.insertAdjacentHTML('beforeend', beaconStr);
    if (window && !window.SponsoredBannerImpressions) {
      window.SponsoredBannerImpressions = {};
    }
    window.SponsoredBannerImpressions[onviewbeacon] = true;
  }
  // Impression Fired Time
  const impressionFiredTime = Date.now();

  if (viewConversionBeacon && mcvisID) {
    persistViewConversionBeacon({
      conversionBeacon: viewConversionBeacon,
      mcvisID
    });
  }

  triggerAdTimeLogs(dataTestId, adLoadStartTime, bannerInitTime, innervateScriptTime, beaconAddTime, impressionFiredTime, adServer);

  element.setAttribute(htmlAttributes.DATA_H1_BEACON, onviewbeacon);
  if (!element.classList.contains('sponsored-product-pod')
      && !element.classList.contains('sponsored-pip-pla-container')) {
    pushImpressionToAdobe(element);
  }
  const newRelicAdType = newRelicDataTestId[dataTestId];
  if (newRelicAdType) {
    triggerNewRelic(newRelicAdType, `impression-${adServer}-${slotId}`);
  }

}

export const triggerBeacon = (element) => {
  let onviewbeacon = element.getAttribute(htmlAttributes.ONVIEW_BEACON_NUCLEUS)
    || element.getAttribute(htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS);
  const dataTestId = element.getAttribute(htmlAttributes.DATA_TEST_ID);
  if (onviewbeacon) {
    handleImpression(element, onviewbeacon, dataTestId);
  } else if (dataTestId === 'default-standard-banner') {
    // set a beacon for default banner to indicate impression sent
    element.setAttribute(htmlAttributes.DATA_H1_BEACON, 'default-banner');
    triggerNewRelic(newRelicConstants.DEFAULT_BANNER, 'impression');
    sendDefaultBannerImpression();
  }
};
