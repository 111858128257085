/* eslint id-length: 0 */
import { htmlAttributes, newRelicConstants } from '../constants';

export const getTestCoordinates = (dimensions) => {
  const topLeft = {
    x: dimensions.left,
    y: dimensions.top
  };
  const topRight = {
    x: Math.max(dimensions.right - 1, 0),
    y: dimensions.top
  };
  const center = {
    x: dimensions.left + Math.floor((dimensions.right - dimensions.left) / 2),
    y: dimensions.top + Math.floor((dimensions.bottom - dimensions.top) / 2) - 1
  };
  const bottomLeft = {
    x: dimensions.left,
    y: dimensions.bottom - 1
  };
  const bottomRight = {
    x: dimensions.right - 1,
    y: dimensions.bottom - 1
  };

  return [
    topLeft,
    topRight,
    center,
    bottomLeft,
    bottomRight
  ];
};

export const getDimensions = (localWindow, element) => {
  let result;

  if (element === localWindow) {
    result = {
      top: 0,
      left: 0,
      bottom: localWindow.innerHeight,
      right: localWindow.innerWidth,
      width: localWindow.innerWidth,
      height: localWindow.innerHeight
    };
  } else if (element.getBoundingClientRect) {
    let {
      bottom,
      height,
      left,
      right,
      top,
      width
    } = element.getBoundingClientRect();

    if (element.id === 'hp_sponsored__hero') {
      top += 10;
      height -= 10;
    }

    result = {
      bottom,
      height,
      left,
      right,
      top,
      width
    };
  } else {
    result = {
      bottom: element.offsetTop + element.offsetHeight,
      height: element.offsetHeight,
      left: element.offsetLeft,
      right: element.offsetLeft + element.offsetWidth,
      top: element.offsetTop,
      width: element.offsetWidth
    };
  }

  return result;
};

export const isElementOrChild = (elementAtPoint, matchingElement) => {
  let result = false;

  // If we found it
  if (elementAtPoint === matchingElement) {
    result = true;
    // If there is something else to check
  } else if (elementAtPoint && elementAtPoint.parentElement) {
    result = isElementOrChild(elementAtPoint.parentElement, matchingElement);
  }

  return result;
};

export const isTransparent = (element) => {
  let opacityIsZero = (element && element.style && element.style.opacity === '0');

  if (!opacityIsZero && element.parentElement) {
    opacityIsZero = isTransparent(element.parentElement);
  }

  return opacityIsZero;
};

const pushToDDO = (event) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.event = window.digitalData.event || [];
  window.digitalData.event.push(event);
};

export const pushImpressionToAdobe = (element) => {
  const campaignId = element?.getAttribute?.(htmlAttributes.DATA_CAMPAIGN_ID);
  const placementId = element?.getAttribute?.(htmlAttributes.DATA_PLACEMENT_ID);
  const slotId = element?.getAttribute?.(htmlAttributes.DATA_SLOT_ID);
  let flightId = element?.getAttribute?.(htmlAttributes.DATA_FLIGHT_ID);

  const creativeId = element?.getAttribute?.(htmlAttributes.DATA_CREATIVE_ID);
  const getImpressionId = (el) => {
    try {
      const onviewBeacon = el?.getAttribute(htmlAttributes.ONVIEW_BEACON_NUCLEUS)
        || el?.getAttribute(htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS);
      const queryString = onviewBeacon.split('?')[1];
      const idField = queryString.split('&')[0];
      const impressionId = idField.split('=')[1];
      return impressionId;
    } catch {
      return '';
    }
  };

  let event = {
    category: { primaryCategory: 'content display' },
    eventInfo: { eventName: 'piq impression' }, // should be updated to be ad server agnostic
    data: {
      impressionID: getImpressionId(element),
      campaignID: campaignId || '',
      placementID: placementId || '',
      slotID: slotId || '',
      flightID: flightId !== 'undefined' ? flightId : ''
    }
  };

  if (creativeId) {
    event.data.creativeID = creativeId;
  }

  pushToDDO(event);

};

export const sendDefaultBannerImpression = () => {
  let event = {
    category: { primaryCategory: 'content display' },
    eventInfo: { eventName: 'piq impression' },
    data: {
      impressionID: newRelicConstants.DEFAULT_BANNER,
      campaignID: newRelicConstants.DEFAULT_BANNER,
      placementID: newRelicConstants.DEFAULT_BANNER,
      slotID: newRelicConstants.DEFAULT_BANNER
    }
  };

  pushToDDO(event);
};

export const shouldTrackImpressions = (queryParams) => {
  const areImpressionsEnabledOnLocalhost = !!queryParams.enableImpressionsOnLocalhost;
  if (areImpressionsEnabledOnLocalhost || !window.location.host.includes('localhost')) {
    return true;
  }
  return false;
};
