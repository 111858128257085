import React from 'react';
import { shape, string, array, number } from 'prop-types';
import { BANNER, bannerTypes, schemas } from '../../constants/constants';
import { initImpressionWatchers } from '../../utils/initImpressionWatchers';
import { get, getFromBannerApi } from '../../utils/axios';
import { triggerNewRelic, triggerNewRelicTimeLog } from '../../utils/helpers';
import { injectRevJetScript, getCreativeId, onAdTagContainerClick } from '../../utils/revjet';
import { newRelicConstants } from '../../constants';
import { determineAdServer } from '../../utils/utils';
import { getBannerQueryParams } from '../../utils/requestUrlHelpers';

function getHeroBannerPosition() {
  const defaultPosition = 2;
  const value = typeof window !== 'undefined' ? window.cookieUtils?.readBrowserCookie('xtt-tntHeroBannerPos') : null;
  return value ? value - 1 : defaultPosition;
}

const insertHeroBanner = ({
  cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
}) => {
  initImpressionWatchers();

  let viewBeacon = data?.banner?.onViewBeacon;
  viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';
  let creativeId = getCreativeId(data?.banner?.bannerURL);
  let updatedCarousel = newCarousel;

  let tempChild = (
    <SponsoredHeroBanner
      cx={cx}
      data={data}
      newCarousel={newCarousel}
      viewBeacon={viewBeacon}
      creativeId={creativeId}
      classNames={classNames}
    />
  );
  // We do not want to set the banner position
  // to the first and last index.
  if (heroBannerPosition > 0 && heroBannerPosition < updatedCarousel?.length - 1) {
    updatedCarousel.splice(heroBannerPosition, 0, tempChild);
    injectRevJetScript({ data, requestConfig: heroRequestConfig });
  }
  return updatedCarousel;
};

const getHeroBanner = ({
  cx, data, heroBannerPosition, heroRequestConfig, classNames
}) => {
  initImpressionWatchers();
  injectRevJetScript({ data, requestConfig: heroRequestConfig });

  let viewBeacon = data?.banner?.onViewBeacon;
  viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';
  let creativeId = getCreativeId(data?.banner?.bannerURL);

  return {
    HeroBanner: (
      <SponsoredHeroBanner
        cx={cx}
        data={data}
        viewBeacon={viewBeacon}
        creativeId={creativeId}
        classNames={classNames}
      />
    ),
    heroBannerPosition,
  };

};

async function getSponsoredHeroBanner({
  newCarousel, cx, classNames, isGuest, experienceContext, browserId, returnSponsoredHeroOnly
}) {
  const bannerSchema = isGuest ? schemas.HP_SPONSORED_AUTH : schemas.HP_SPONSORED;
  const customerType = experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  const schemaId = 'hp_sponsored__hero';
  const validBrowserId = browserId || experienceContext?.cookie?.adobeCookie?.MCMID;
  const heroBannerPosition = getHeroBannerPosition();
  const heroRequestConfig = {
    schema: bannerSchema,
    experienceContext,
    bannerType: bannerTypes.HERO,
    schemaId,
  };
  const requestConfig = {
    browserId: validBrowserId,
    schema: bannerSchema,
    schemaId,
    experienceContext,
    bannerType: bannerTypes.HERO,
    ignoreStoreId: true,
    heroBannerPosition,
    customerType
  };
  const apiRequestTimestamp = new Date().getTime();
  heroRequestConfig.startTime = apiRequestTimestamp;
  let updatedCarousel = [];
  try {
    let queryParams = getBannerQueryParams(requestConfig);
    let response;
    const hosts = experienceContext.hosts;
    const bannerHost = hosts.apionline;
    response = await getFromBannerApi(bannerHost, {
      params: queryParams,
      withCredentials: true
    });
    triggerNewRelic(newRelicConstants.HERO_BANNER, `API-${response.status}`);
    let apiResponseTimestamp = new Date().getTime();
    if (response.status === 200 && response?.data) {
      const { data } = response;
      const bannerApiAdServer = determineAdServer(data);
      // SEND TIMESTAMPS TO CALCULATE API RESPONSE TIME
      triggerNewRelicTimeLog(newRelicConstants.HERO_BANNER_API_RESPONSE, apiRequestTimestamp, apiResponseTimestamp, bannerApiAdServer);
      // SEND TIME FROM BANNER INITIATED TO BANNER WINNER RECEIVED
      triggerNewRelicTimeLog(newRelicConstants.HERO_BANNER_AD_INIT_TO_WINNER, apiRequestTimestamp, apiResponseTimestamp, bannerApiAdServer);
      if (returnSponsoredHeroOnly) {
        return (getHeroBanner({
          cx, data, heroBannerPosition, heroRequestConfig, classNames
        }));
      }
      (
        updatedCarousel = insertHeroBanner({
          cx, data, newCarousel, heroBannerPosition, heroRequestConfig, classNames
        })
      );
    }

  } catch (error) {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('error-boundary.error', {
        error,
        id: newRelicConstants.HERO_BANNER
      });
    }
  }
  return updatedCarousel;
}

const SponsoredHeroBanner = (props) => {
  let{
    cx,
    data,
    newCarousel,
    viewBeacon,
    creativeId,
    classNames
  } = props;
  return (
    <div
      id="hp_sponsored__hero_container"
      className={cx ? cx(classNames) : classNames}
      onClick={onAdTagContainerClick}
      style={{ cursor: 'pointer' }}
      role="presentation"
    >
      <div
        id="hp_sponsored__hero"
        key={`item-${newCarousel.length}`}
        className={cx ? cx(classNames) : classNames}
        style={{ pointerEvents: 'none' }}
        data-testid="sponsored-hero-banner"
      >
        {newCarousel[0] ? newCarousel[0] : null}
      </div>
    </div>
  );
};

SponsoredHeroBanner.propTypes = {
  cx: string,
  data: shape({
    banner: shape({
      campaignId: string,
      placementId: string,
      slotId: number
    })
  }),
  newCarousel: array,
  viewBeacon: string,
  creativeId: string,
  classNames: string
};

SponsoredHeroBanner.defaultProps = {
  cx: '',
  data: {},
  newCarousel: [],
  viewBeacon: '',
  creativeId: '',
  classNames: ''
};

export { getSponsoredHeroBanner };