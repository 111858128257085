/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Carousel } from '@one-thd/sui-carousel';
import { getSponsoredHeroBanner } from '@thd-olt-component-react/sponsored-content';
import { isGuest } from '../../utils/index';

export function HeroCarousel({
  children, customRenderId, id: entryId, isAutoplay, __typename
}) {
  let initialCarouselItems = [];
  const experienceContext = useContext(ExperienceContext);
  const channel = experienceContext.channel;
  const [sponsoredHero, setSponsoredHero] = useState(null);
  const [sponsoredHeroPosition, setSponsoredHeroPosition] = useState(2);
  const [gotSponsored, setGotSponsored] = useState(false);
  const [swiperTimer, setSwiperTimer] = useState(
    isAutoplay ? { autoplay: { delay: 10000, pauseOnMouseEnter: true } } : null
  );

  const thdCustomer = useThdCustomer();
  const revJetConfigRehub = useConfigService('revJetConfigMap');
  let externalRevJetConfig = null;
  if (revJetConfigRehub) {
    try {
      externalRevJetConfig = JSON.parse(revJetConfigRehub);
    } catch (err) {
      // newrelic logging coming in future story
    }
  }

  for (let [index, child] of children.entries()) {
    initialCarouselItems.push(<div key={`item_${index}`}>{child}</div>);

    // Only one carousel image needs to be rendered server-side
    if (typeof window === 'undefined') break;
  }

  if (sponsoredHero) {
    initialCarouselItems.splice(sponsoredHeroPosition, 0, sponsoredHero);
  }

  async function checkForSponsoredBanner() {
    const sponsoredHeroBanner = await getSponsoredHeroBanner({
      channel,
      classNames: 'sui-w-full',
      isGuest: isGuest(),
      experienceContext,
      externalRevJetConfig,
      browserId: thdCustomer?.mcvisID || '',
      returnSponsoredHeroOnly: true
    });
    setGotSponsored(true);
    setSponsoredHero(sponsoredHeroBanner?.HeroBanner || null);
    setSponsoredHeroPosition(sponsoredHeroBanner?.heroBannerPosition || null);
  }

  useEffect(() => {
    if (!gotSponsored && thdCustomer?.mcvisID) {
      checkForSponsoredBanner();
    }
    let swiperDelay;
    if (isAutoplay) {
      swiperDelay = setTimeout(() => {
        setSwiperTimer({ autoplay: { delay: 5000, pauseOnMouseEnter: true } });
      }, 8000);
    }
    return () => {
      clearTimeout(swiperDelay);
    };
  }, [customRenderId, thdCustomer?.mcvisID, gotSponsored]);

  return (
    <ImpressionProvider
      data={{
        id: entryId || '',
        name: customRenderId,
        component: __typename,
        type: 'content',
      }}
    >
      <div id="hero-carousel">
        <Carousel autoplay={isAutoplay} disableShadow disableMargin type="hero" SwiperProps={swiperTimer}>
          {initialCarouselItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
        </Carousel>
      </div>
    </ImpressionProvider>
  );
}
