/* eslint-disable max-len */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from 'react';
import { useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import { Carousel } from '@one-thd/sui-carousel';
import {
  shape, bool, string, arrayOf, number
} from 'prop-types';
import { getSponsoredHeroBanner } from '@thd-olt-component-react/sponsored-content';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { isGuest } from '../../../utils/getUserInfo';
import { ImpressionableBay } from '../bento-bay/ImpressionableBay';
import './stencil-overrides.scss';

export function BentoHeroCarousel({ carouselData, customRenderId = null, isAutoplay = false, position = 0 }) {
  let initialCarouselItems = [];
  const experienceContext = useContext(ExperienceContext);
  const channel = experienceContext.channel;
  const [sponsoredHero, setSponsoredHero] = useState(null);
  const [sponsoredHeroFetched, setSponsoredHeroFetched] = useState(false);

  const swiperTimer = isAutoplay ? { autoplay: { delay: 8000, pauseOnMouseEnter: true } } : null;
  const thdCustomer = useThdCustomer();
  const revJetConfigRehub = useConfigService('revJetConfigMap');
  const callBannerApiForHero = useConfigService('callBannerApiForHero');
  let externalRevJetConfig = null;
  if (revJetConfigRehub) {
    try {
      externalRevJetConfig = JSON.parse(revJetConfigRehub);
    } catch (err) {
      // newrelic logging coming in future story
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (let [index, child] of carouselData.entries()) {
    initialCarouselItems.push(<div key={`item_${index}`}>{child}</div>);
    // Only one carousel image needs to be rendered server-side
    if (typeof window === 'undefined') break;
  }

  if (sponsoredHero) {
    initialCarouselItems.splice(1, 0, sponsoredHero);
  }

  async function checkForSponsoredBanner() {
    const sponsoredHeroBanner = await getSponsoredHeroBanner({
      channel,
      classNames: 'sui-w-full',
      isGuest: isGuest(),
      experienceContext,
      externalRevJetConfig,
      browserId: thdCustomer?.mcvisID || '',
      returnSponsoredHeroOnly: true,
      callBannerApiForHero
    });
    setSponsoredHeroFetched(true);
    setSponsoredHero(sponsoredHeroBanner?.HeroBanner || null);
  }

  useEffect(() => {
    if (!sponsoredHeroFetched && thdCustomer?.mcvisID) {
      checkForSponsoredBanner();
    }
  }, [thdCustomer?.mcvisID, sponsoredHeroFetched]);

  // Otherwise render carousel
  return (
    <div
      id="hero-carousel"
      className="remove-top-stencil-margin sui-col-span-12 lg:sui-col-span-8 sui-order-1 lg:sui-aspect-[3.6/1] lg:sui-order-2 sui-w-full"
    >
      <Carousel type="peekless" autoplay={isAutoplay} disableShadow disableMargin SwiperProps={swiperTimer}>
        {initialCarouselItems.map((item, index) => {
          const isSponsoredHero = sponsoredHero && index === 1;

          if (isSponsoredHero) {
            return <React.Fragment key={`hero-item-${index}`}>{item}</React.Fragment>;
          }

          return (
            <ImpressionableBay
              key={`hero-image-${index}`}
              bentoBayData={item.props.children || {}}
              carouselPosition={index + 1}
              position={position}
            />
          );
        })}
      </Carousel>
    </div>
  );
}

BentoHeroCarousel.propTypes = {
  carouselData: arrayOf(shape()).isRequired,
  isAutoplay: bool,
  customRenderId: string,
  position: number
};
