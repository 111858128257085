/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { SponsoredBanner } from '@thd-olt-component-react/sponsored-content';
import { BentoBay } from '../bento-bay/BentoBay';
import { BentoHeroCarousel } from './BentoHeroCarousel';

// Responsible for rendering the grid and which cells of the grid to show visually at each breakpoint.
// Breakdown (4 bay layout):
//   Mobile/Tablet breakpoint --
//     - 1st cell has 100% width (full width).
//     - 2nd cell has 50% width.
//     - 3rd cell has 50% width.
//     - 4th cell has 100% width.
//   Desktop breakpoint --
//     - 1st cell has ~66% width.
//     - 2nd cell has ~33% width.
//     - 3rd cell has 50% width.
//     - 4th cell has 50% width.
const BentoHeroContainer = ({ data }) => {
  // A breakpoint cannot be both mobile and not mobile at the same time, and if they are the same (most likely false)
  // it means we have not fully loaded on the client yet. We want to wait until we have fully loaded on the client
  // otherwise we could be creating the wrong impression ID, clickID, (the ID will contain the wrong position for the
  // Bay in a 5 bay layout).
  const { lesser: isMobile } = useBreakpoint('lg', { ssr: true });
  const [impressionsAreReady, setImpressionsAreReady] = useState(false);
  const [firstBayPosition, setFirstBayPosition] = useState(1);
  const [secondBayPosition, setSecondBayPosition] = useState(2);

  useEffect(() => {
    if (!impressionsAreReady) {
      setImpressionsAreReady(true);
      setFirstBayPosition(isMobile ? 1 : 2);
      setSecondBayPosition(isMobile ? 2 : 1);
    }
  }, [isMobile]);

  if (!data) {
    return null;
  }

  const {
    firstBay, secondBay, thirdBay, fourthBay, bentoSponsoredBanner
  } = data;

  const { schema = null, specialDefaultBanner = {} } = bentoSponsoredBanner || {};

  let contentfulDefaultBanner = null;
  if (specialDefaultBanner.imgDesktop && specialDefaultBanner.imgMobile) {
    contentfulDefaultBanner = { specialDefaultBanner };
  }

  const fourBentoBayLayout = (
    <div className="sui-grid sui-grid-cols-12 sui-pt-1 sui-gap-4 md:sui-gap-6 lg:sui-gap-8 sui-bg-primary">
      <div className="sui-col-span-12 lg:sui-col-span-8 sui-aspect-[1.43/1] md:sui-aspect-[3/1] lg:sui-aspect-[3.33/1] sui-h-full sui-w-full">
        <BentoBay bentoBayData={firstBay} position={1} impressionReady={impressionsAreReady} />
      </div>
      <div className="sui-col-span-6 lg:sui-col-span-4 sui-aspect-[1/1.47] md:sui-aspect-[1.45/1] lg:sui-aspect-[1.6/1] sui-h-full sui-w-full">
        <SponsoredBanner
          bentoBayData={bentoSponsoredBanner}
          contentfulDefaultBanner={contentfulDefaultBanner}
          impressionReady={impressionsAreReady}
          position={2}
          schema={schema}
        />
      </div>
      <div className="sui-col-span-6 sui-aspect-[1/1.47] md:sui-aspect-[1.45/1] lg:sui-aspect-[4.93/1] sui-h-full sui-w-full">
        <BentoBay bentoBayData={secondBay} position={3} impressionReady={impressionsAreReady} />
      </div>
      <div className="sui-col-span-12 lg:sui-col-span-6 sui-aspect-[2.86/1] md:sui-aspect-[6/1] lg:sui-aspect-[4.93/1] sui-h-full sui-w-full">
        <BentoBay bentoBayData={thirdBay} position={4} impressionReady={impressionsAreReady} />
      </div>
    </div>
  );

  const fiveBentoBayLayout = (
    <div className="sui-grid sui-grid-flow-row sui-pt-1 sui-grid-cols-12 sui-gap-4 sui-bg-primary sui-pb-4">
      { firstBay?.isCarousel ? (
        <BentoHeroCarousel carouselData={firstBay?.components} position={firstBayPosition} isAutoplay={firstBay?.isAutoplay} />
      ) : (
        <div className="sui-col-span-12 lg:sui-col-span-8 sui-order-1 lg:sui-order-2 sui-w-full">
          <BentoBay bentoBayData={firstBay} position={firstBayPosition} impressionReady={impressionsAreReady} isSponsored />
        </div>
      )}
      {/* Sponsored Content */}
      <div className="sui-col-span-12 lg:sui-col-span-12 sui-order-2 lg:sui-order-3 lg:sui-cols-4 sui-w-full">
        <SponsoredBanner
          bentoBayData={bentoSponsoredBanner}
          contentfulDefaultBanner={contentfulDefaultBanner}
          data-testid="sponsored-banner"
          impressionReady={impressionsAreReady}
          position={secondBayPosition}
          schema={schema}
        />
      </div>
      <div className="sui-col-span-12 lg:sui-col-span-4 sui-order-3 lg:sui-order-1 sui-w-full sui-h-full">
        <BentoBay bentoBayData={secondBay} position={secondBayPosition} impressionReady={impressionsAreReady} />
      </div>
      <div className="sui-col-span-12 lg:sui-col-span-8 sui-order-4 sui-aspect-[2.7/1] lg:sui-aspect-[3.3/1] sui-w-full sui-h-full">
        <BentoBay bentoBayData={thirdBay} position={3} impressionReady={impressionsAreReady} />
      </div>
      <div className="sui-col-span-12 lg:sui-col-span-4 sui-order-5 sui-aspect-[2.7/1] lg:sui-aspect-[1.61/1] sui-w-full sui-h-full">
        <BentoBay bentoBayData={fourthBay} position={4} impressionReady={impressionsAreReady} />
      </div>
    </div>
  );

  return (
    <>
      {fourthBay ? fiveBentoBayLayout : fourBentoBayLayout}
    </>
  );
};

BentoHeroContainer.propTypes = {
  data: shape({
    firstBay: shape({}),
    secondBay: shape({}),
    thirdBay: shape({}),
    fourthBay: shape({}),
    bentoSponsoredBanner: shape({}),
  }).isRequired,
};

export { BentoHeroContainer };
