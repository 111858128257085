export const htmlSelectors = {
  NUCLEUS_BANNER: '[id*=sponsored-standard-banner-nucleus]',
  NUCLEUS_HERO_BANNER: '[id*=hp_sponsored__hero]',
  NUCLEUS_HERO_WRAPPER: '[id*=hp_sponsored__hero_container]',
  DEFAULT_BANNER: '[id*=default_banner_carousel]',
  NUCLEUS_SKYSCRAPER_BANNER: '[id*="sponsored__skyscraper"]',
  NUCLEUS_PIP_BANNER: '[id*="pip_sponsored__banner_A"]',
  NUCLEUS_STANDARD_BANNER: '.nucleus-banner',
  NUCLEUS_INGRID_BANNER: '[id*="__ingrid"]',

  CERTONA_WRAPPER: '.certona__wrapper',
  VISUALLY_SIMILAR: '.visually-similar',
};