import React from 'react';
import { shape, number, bool } from 'prop-types';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ImageHotspot } from '@thd-olt-component-react/image-hotspot';

// IF NOT IN A CAROUSEL, PROP WILL NOT BE PASSED AND THIS WILL DEFAULT TO 0
const BayImage = ({ bayImageData, carouselPosition = 0, isHotspotImage = false, position = 0 }) => {
  const {
    mobilePreviewImage,
    previewImage,
    id,
    altText = '',
    title = ''
  } = bayImageData?.components?.[0] || bayImageData;

  function getBayImageUrl(imageData) {
    return imageData?.damDownloadedContent?.url
      || imageData?.damContentSelector?.assetData?.[0]?.selectedImageUrl || '';
  }

  const mobileImageUrl = getBayImageUrl(mobilePreviewImage);
  const desktopImageUrl = getBayImageUrl(previewImage);

  const isMobileViewport = useBreakpoint('md').lesser;

  const mobileHotspot = mobilePreviewImage?.imageHotspot;
  const desktopHotspot = previewImage?.imageHotspot;

  const imageAltText = altText || title;

  if (isHotspotImage) {
    return (
      <ErrorBoundary name="image-hotspot">
        <QueryProvider cacheKey="image-hotspot">
          <span style={{ width: '100%' }}>
            <ImageHotspot
              componentId={isMobileViewport ? mobileHotspot?.id : desktopHotspot?.id}
              componentClass="ImageHotspot"
              ssrImageData={{
                mobileImageSrc: mobileImageUrl,
                responsiveImages: [{
                  imageSrc: desktopImageUrl,
                  mediaQuery: '(min-width: 1024px)'
                }]
              }}
              parentId={id}
              position={position}
            />
          </span>
        </QueryProvider>
      </ErrorBoundary>
    );
  }

  return (
    <picture style={{ width: '100%' }}>
      <source
        media="(min-width: 1024px)"
        alt={imageAltText}
        className="sui-w-full sui-h-auto sui-top-0 sui-left-0"
        srcSet={desktopImageUrl}
        data-testid="bay-image-desktop"
      />
      {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
      <img
        src={mobileImageUrl}
        alt={imageAltText}
        className="sui-w-full sui-h-auto sui-top-0 sui-left-0"
        data-testid="bay-image-img"
      />
    </picture>
  );
};

BayImage.propTypes = {
  bayImageData: shape({}).isRequired,
  position: number,
  carouselPosition: number,
  isHotspotImage: bool,
};

BayImage.displayName = 'BayImage';

export { BayImage };
