/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import {
  arrayOf,
  string,
  number,
  shape
} from 'prop-types';
import { useImpression, ImpressionProvider } from '@thd-olt-component-react/impression';
import { ImageHotspot } from '@thd-olt-component-react/image-hotspot';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { HomepageContext } from './context/HomepageContext';
import { HeroFlattenVideo } from './HeroFlattenVideo';
import { CUSTOMER_TYPES } from '../utils';
import { selectPreferredImage, getHeroImageType } from '../utils/publicLinks';

export function HeroFlattenImage({
  id,
  layoutRendererPrerenderedChildIndex,
  link,
  mobilePreviewImage,
  previewImage,
  proAnalyticsCampaign,
  proAnalyticsComponent,
  title,
}) {
  const { customerType, proCustomerSegment } = useContext(HomepageContext);
  const altText = title;
  const isB2B = customerType === CUSTOMER_TYPES.B2B;
  const mobileMinWidthBreakpoint = isB2B ? '(min-width: 640px)' : '(min-width: 768px)';
  const largeViewportImageSrcUrl = selectPreferredImage({
    damImage: previewImage,
  });
  const smallViewportImageSrcUrl = selectPreferredImage({
    damImage: mobilePreviewImage,
  });

  const heroImageType = getHeroImageType(largeViewportImageSrcUrl);

  const impressionContextData = useImpression({
    data: {
      id,
      name: 'HeroFlattenImage',
      component: 'Hero',
      position: layoutRendererPrerenderedChildIndex + 1,
      type: 'content',
      ...(heroImageType ? { category: heroImageType } : {}),
    },
  });

  const isHeroImageHotspot = !!previewImage.imageHotspot?.id;
  const isMobileViewport = useBreakpoint('md').lesser;

  if (isHeroImageHotspot) {
    const mobileHotspot = mobilePreviewImage.imageHotspot;
    const desktopHotspot = previewImage.imageHotspot;
    const ssrImageData = {
      mobileImageSrc: mobileHotspot?.imageUrl,
      responsiveImages: [
        {
          imageSrc: desktopHotspot?.imageUrl,
          mediaQuery: mobileMinWidthBreakpoint,
        }
      ]
    };

    return (
      <ErrorBoundary name="image-hotspot">
        <QueryProvider cacheKey="image-hotspot">
          <div
            key={id}
            ref={impressionContextData.ref}
            // eslint-disable-next-line react/no-unknown-property
            clickid={impressionContextData.clickID}
          >
            <ImpressionProvider
              data={{
                id,
                name: 'HeroFlattenImage',
                component: 'Hero',
                type: 'content',
              }}
            >
              <ImageHotspot
                componentId={isMobileViewport
                  ? mobileHotspot.id : desktopHotspot.id}
                componentClass={isMobileViewport
                  ? mobileHotspot.componentName : desktopHotspot.componentName}
                position={layoutRendererPrerenderedChildIndex + 1}
                ssrImageData={ssrImageData}
                parentId={id}
              />
            </ImpressionProvider>
          </div>
        </QueryProvider>
      </ErrorBoundary>
    );
  }

  if (heroImageType === 'mp4') {
    return (
      <div
        key={id}
        ref={impressionContextData.ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={impressionContextData.clickID}
      >
        <HeroFlattenVideo
          videoURL={largeViewportImageSrcUrl}
          altText={altText}
        />
      </div>
    );
  }

  if (!largeViewportImageSrcUrl && !smallViewportImageSrcUrl) {
    return null;
  }

  function handleClickAnalytics() {
    if (isB2B) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('hero-flatten.content-click', {
        widgetId: id,
        widgetTemplates: proCustomerSegment,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  }

  const imageClasses = 'sui-w-full sui-h-auto sui-top-0 sui-left-0';

  return (

    <a
      href={link}
      key={id}
      onClick={handleClickAnalytics}
      ref={impressionContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionContextData.clickID}
    >
      <picture>
        <source
          className={imageClasses}
          alt={`Image for ${altText}`}
          srcSet={largeViewportImageSrcUrl}
          media={mobileMinWidthBreakpoint}
        />
        {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
        <img
          className={imageClasses}
          alt={altText}
          src={smallViewportImageSrcUrl}
        />
      </picture>
    </a>
  );
}

HeroFlattenImage.propTypes = {
  link: string,
  id: string,
  mobilePreviewImage: shape({
    damContentSelector: shape({
      assetData: arrayOf(
        shape({
          selectedImageUrl: string,
        })
      ),
    }),
    damDownloadedContent: shape({
      url: string,
    }),
    imageHotspot: shape({
      id: string,
      componentName: string,
    })
  }),
  previewImage: shape({
    damContentSelector: shape({
      assetData: arrayOf(
        shape({
          selectedImageUrl: string,
        })
      ),
    }),
    damDownloadedContent: shape({
      url: string,
    }),
    imageHotspot: shape({
      id: string,
      componentName: string,
    })
  }),

  title: string,
  layoutRendererPrerenderedChildIndex: number,
  proAnalyticsCampaign: string,
  proAnalyticsComponent: string,
};

HeroFlattenImage.defaultProps = {
  link: '',
  id: '',
  layoutRendererPrerenderedChildIndex: undefined,
  proAnalyticsCampaign: undefined,
  proAnalyticsComponent: undefined,
  title: undefined,
  mobilePreviewImage: undefined,
  previewImage: undefined,
};
