export const newRelicConstants = {
  // ad types
  STANDARD_BANNER: 'sponsoredStandardBanner',
  SKYSCRAPER_BANNER: 'sponsoredSkyscraperBanner',
  PIP_BANNER: 'sponsoredPipBanner',
  INGRID_BANNER: 'sponsoredInGridBanner',
  HERO_BANNER: 'sponsoredHeroBanner',
  NON_ENDEMIC_BANNER: 'nonEndemicBanner',
  CAROUSEL_SEO: 'sponsoredPipsemCarousel',
  DEFAULT_BANNER: 'defaultStandardBanner',

  // event types
  LOAD: 'load',
  IMPRESSION: 'impression',
  CLICK: 'click',
  AD_BLOCKED: 'adblocker_enabled',

  // latency tracking
  STANDARD_BANNER_API_RESPONSE: 'sponsored-banner.time-until-api-response',
  STANDARD_BANNER_AD_LOAD: 'sponsored-banner.time-until-ad-load',
  STANDARD_BANNER_AD_INIT_TO_WINNER: 'sponsored-banner.time-from-init-to-winner',
  STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'sponsored-banner.time-from-innervate-script-to-load',
  STANDARD_BANNER_AD_INIT_TO_IMPRESSION: 'sponsored-banner.time-from-init-to-impression',
  STANDARD_BANNER_AD_LOAD_TO_IMPRESSION: 'sponsored-banner.time-from-load-to-impression',
  STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'sponsored-banner.time-from-innervate-script-to-impression',
  STANDARD_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'sponsored-banner.time-from-beacon-add-to-impression',

  SKYSCRAPER_BANNER_API_RESPONSE: 'skyscraper-banner.time-until-api-response',
  SKYSCRAPER_BANNER_AD_LOAD: 'skyscraper-banner.time-until-ad-load',
  SKYSCRAPER_BANNER_AD_INIT_TO_WINNER: 'skyscraper-banner.time-from-init-to-winner',
  SKYSCRAPER_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'skyscraper-banner.time-from-innervate-script-to-load',
  SKYSCRAPER_BANNER_AD_INIT_TO_IMPRESSION: 'skyscraper-banner.time-from-init-to-impression',
  SKYSCRAPER_BANNER_AD_LOAD_TO_IMPRESSION: 'skyscraper-banner.time-from-load-to-impression',
  SKYSCRAPER_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'skyscraper-banner.time-from-innervate-script-to-impression',
  SKYSCRAPER_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'skyscraper-banner.time-from-beacon-add-to-impression',

  PIP_BANNER_API_RESPONSE: 'pip-banner.time-until-api-response',
  PIP_BANNER_AD_LOAD: 'pip-banner.time-until-ad-load',
  PIP_BANNER_AD_INIT_TO_WINNER: 'pip-banner.time-from-init-to-winner',
  PIP_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'pip-banner.time-from-innervate-script-to-load',
  PIP_BANNER_AD_INIT_TO_IMPRESSION: 'pip-banner.time-from-init-to-impression',
  PIP_BANNER_AD_LOAD_TO_IMPRESSION: 'pip-banner.time-from-load-to-impression',
  PIP_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'pip-banner.time-from-innervate-script-to-impression',
  PIP_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'pip-banner.time-from-beacon-add-to-impression',

  INGRID_BANNER_API_RESPONSE: 'ingrid-banner.time-until-api-response',
  INGRID_BANNER_AD_LOAD: 'ingrid-banner.time-until-ad-load',
  INGRID_BANNER_AD_INIT_TO_WINNER: 'ingrid-banner.time-from-init-to-winner',
  INGRID_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'ingrid-banner.time-from-innervate-script-to-load',
  INGRID_BANNER_AD_INIT_TO_IMPRESSION: 'ingrid-banner.time-from-init-to-impression',
  INGRID_BANNER_AD_LOAD_TO_IMPRESSION: 'ingrid-banner.time-from-load-to-impression',
  INGRID_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'ingrid-banner.time-from-innervate-script-to-impression',
  INGRID_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'ingrid-banner.time-from-beacon-add-to-impression',

  HERO_BANNER_API_RESPONSE: 'hero-banner.time-until-api-response',
  HERO_BANNER_AD_LOAD: 'hero-banner.time-until-ad-load',
  HERO_BANNER_AD_INIT_TO_WINNER: 'hero-banner.time-from-init-to-winner',
  HERO_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD: 'hero-banner.time-from-innervate-script-to-load',
  HERO_BANNER_AD_INIT_TO_IMPRESSION: 'hero-banner.time-from-init-to-impression',
  HERO_BANNER_AD_LOAD_TO_IMPRESSION: 'hero-banner.time-from-load-to-impression',
  HERO_BANNER_AD_INNERVATE_SCRIPT_TO_IMPRESSION: 'hero-banner.time-from-innervate-script-to-impression',
  HERO_BANNER_AD_BEACON_ADDED_TO_IMPRESSION: 'hero-banner.time-from-beacon-add-to-impression',

  NON_ENDEMIC_BANNER_REQUEST: 'nonendemic-banner.time-until-requested',
  NON_ENDEMIC_BANNER_AD_LOAD: 'nonendemic-banner.time-until-ad-load',

  // a/b testing metric
  PIP_BANNER_TARGETING_MODEL: 'sponsoredPipBanner-targeting-model'
};
export const newRelicDataTestId = {
  'sponsored-standard-banner': newRelicConstants.STANDARD_BANNER,
  'sponsored-skyscraper-banner': newRelicConstants.SKYSCRAPER_BANNER,
  'sponsored-hero-banner': newRelicConstants.HERO_BANNER,
  'sponsored-pip-banner': newRelicConstants.PIP_BANNER,
  'default-standard-banner': newRelicConstants.DEFAULT_BANNER,
  'sponsored-ingrid-banner': newRelicConstants.INGRID_BANNER,
};