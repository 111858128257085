import {
  getApiMockParam,
  getCustomerType,
  getEventFilterIds,
  getOrganicSkus,
  getViewedSku,
  setAppId
} from './queryParamsHelpers';
import { BANNER } from '../constants/constants';
import { isServer } from './helpers';

export const getBannerQueryParams = (requestConfig) => {
  const queryParams = {};
  // first build common field for all banner types
  queryParams.schema = requestConfig.schema;
  queryParams.appid = setAppId(requestConfig);
  const browserId = requestConfig?.browserId;
  if (browserId) {
    queryParams.browserid = browserId;
  }
  queryParams.customerType = (requestConfig.customerType) ? requestConfig.customerType : 'b2c';
  const bannerType = requestConfig.bannerType;
  if (bannerType) {
    queryParams.bannerType = bannerType;
  }
  const appName = window?.experienceMetadata?.name;
  if (appName) {
    queryParams.appName = appName;
  }
  if (!requestConfig.ignoreStoreId) {
    const storeId = requestConfig?.experienceContext?.useStoreId || requestConfig?.experienceContext?.store?.storeId;
    if (storeId) {
      queryParams.storeId = storeId;
    } else {
      queryParams.storeId = 8119;
    }
  }

  // utilizeRvData param is being passed from exp(my-homepage, local-ad) which set to true,
  if (requestConfig?.utilizeRvData) {
    const customerIdentifier = !isServer() && requestConfig.guestUserID;
    if (customerIdentifier) {
      queryParams.customerIdentifier = customerIdentifier;
    }
  }

  if (requestConfig?.heroBannerPosition) {
    queryParams.bannerPosition = requestConfig.heroBannerPosition;
  }

  return queryParams;

};

export const getQueryParams = (requestConfig) => {
  const queryParams = {
    schema: requestConfig.schema,
    appid: setAppId(requestConfig),
    show: requestConfig?.show || BANNER,
  };

  const addIfExists = (key, value) => {
    if (value) queryParams[key] = value;
  };

  addIfExists('browserid', requestConfig?.browserId);
  addIfExists('viewedsku', getViewedSku(requestConfig));
  addIfExists('customerType', getCustomerType(requestConfig));
  addIfExists('mock', getApiMockParam());
  addIfExists('bannerType', requestConfig.bannerType);
  addIfExists('appName', window?.experienceMetadata?.name);
  addIfExists('storeid', requestConfig?.experienceContext?.useStoreId || requestConfig?.experienceContext?.store?.storeId);
  addIfExists('keyword', requestConfig?.pageContext?.keyword);
  addIfExists('matchProducts', getOrganicSkus(requestConfig));
  addIfExists('customerIdentifier', requestConfig.utilizeRvData && !getOrganicSkus(requestConfig) ? requestConfig.guestUserID : null);
  addIfExists('abTestGroup', requestConfig.abTestGroup);

  // existing implementation
  const category = requestConfig?.pageContext?.data?.searchModel?.metadata?.categoryID;
  if (category) {
    queryParams.category = category;
  }

  const nValue = getEventFilterIds(requestConfig.pageContext);
  if (nValue) {
    queryParams.nValue = nValue;
  }

  if (!category && !nValue) {
    // if category doesn't exist from pagecontext
    // we need to send slugId
    if (requestConfig?.slugId) {
      queryParams.nValue = requestConfig.slugId;
    }
  }

  return queryParams;
};
