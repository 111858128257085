/* eslint no-unused-expressions: 0 */
import { checkVisibility } from './checkVisibility';
import { htmlSelectors } from '../constants';
import { shouldTrackImpressions } from './impressionHelpers';
import { getUrlParams } from './helpers';

function checkElsInDOM() {
  const certonaWrapperEl = document.querySelector(htmlSelectors.CERTONA_WRAPPER);
  const visuallySimilarEl = document.querySelector(htmlSelectors.VISUALLY_SIMILAR);
  return (certonaWrapperEl
    && window.getComputedStyle(document.querySelector(htmlSelectors.CERTONA_WRAPPER))?.display !== 'none')
    || (visuallySimilarEl
      && window.getComputedStyle(
        document.querySelector(htmlSelectors.VISUALLY_SIMILAR)
      )?.display !== 'none');
}

function checkContainer() {
  // if the container is visible on the page
  if (checkElsInDOM()) {
    checkVisibility();
  } else {
    setTimeout(checkContainer, 50); // wait 50 ms, then try again
  }
}

export const initImpressionWatchers = (() => {
  let areGlobalListenersAdded = false;

  return (componentsToWatchKeys) => {
    if (typeof window !== 'undefined') {
      const queryParams = getUrlParams(window?.location?.href);
      if (shouldTrackImpressions(queryParams)) {
        if (!areGlobalListenersAdded) {
          window.addEventListener('resize', () => checkVisibility());
          window.addEventListener('scroll', () => checkVisibility());
          window.addEventListener('load', () => {
            checkContainer();
            initImpressionWatchers(componentsToWatchKeys);
          });
          areGlobalListenersAdded = true;
        }

        componentsToWatchKeys?.forEach((key) => {
          const component = document.querySelector(htmlSelectors[key]);

          if (component?.getAttribute('data-impression-handler-attached') !== 'true') {
            component.addEventListener('transitionend', () => checkVisibility(htmlSelectors[key]));
            component.setAttribute('data-impression-handler-attached', 'true');
          }
        });
      }
    }
  };
})();

export const initHeroImpressionWatchers = (() => {
  let heroCarouselWrapper = document.querySelector(htmlSelectors.NUCLEUS_HERO_WRAPPER)?.parentNode?.parentNode;
  heroCarouselWrapper?.addEventListener('transitionend', () => checkVisibility());
});

export const initDefaultBannerImpressionWatchers = (() => {
  let defaultBanner = document.querySelector(htmlSelectors.DEFAULT_BANNER);
  if (defaultBanner) {
    checkVisibility();
  }
});