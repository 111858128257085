import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { useStore } from '@thd-nucleus/experience-context';
import { getSchema, getSkus, getUrlParams } from './helpers';

export const getOrganicSkus = ({ pageContext, eventBusData, utilizeRvData }) => {
  let skus;
  if (eventBusData?.fbtSkus) {
    skus = eventBusData.fbtSkus.join();
  } else {
    skus = getSkus(pageContext, utilizeRvData);
  }

  return skus;
};

export const getViewedSku = ({ pageContext }) => {
  return pageContext?.data?.itemId;
};

export const getEventFilterIds = ({ data }) => {
  let filterId = '';

  if (data?.searchModel?.appliedDimensions) {
    data.searchModel.appliedDimensions.forEach((element) => {
      if (element.label === 'The Home Depot Events') {
        element.refinements.forEach((ref) => {
          filterId = ref.refinementKey;
        });
      }
    });
  }

  return filterId;
};

export const getCustomerType = ({ experienceContext }) => {
  let customerType = 'b2c';

  if (experienceContext?.customer?.type === 'b2b') {
    customerType = 'b2b';
  }

  return customerType;
};

export const getApiMockParam = () => {
  let params = getUrlParams(window.location.href);
  let mock = '';

  if (params.useSponsoredMock) {
    mock = true;
  }

  return mock;
};

export const setAppId = ({ experienceContext }) => {

  if (experienceContext?.isConsumerApp) {
    return 'mobileapp';
  }
  switch (experienceContext?.channel?.toLowerCase()) {
  case 'desktop': return 'desktop';
  case 'mobile': return 'mobileweb';
  case 'tablet': return 'tablet';
  default:
    // eslint-disable-next-line no-console
    console.warn(`Invalid channel type: ${experienceContext?.channel}`);
    return 'desktop';
  }
};

export const getApiParams = ({
  guestUserID, experienceContext, pageContext, schema
}) => {
  const pageType = getSchema({ schema, pageContext });
  const channel = setAppId({ experienceContext });
  const customerType = experienceContext.customer?.type ? experienceContext.customer.type : 'b2c';
  const category = pageContext?.data?.searchModel?.metadata?.categoryID;
  const keyword = pageContext?.keyword;
  const customerIdentifier = guestUserID;
  return {
    pageType,
    channel,
    customerType,
    category,
    keyword,
    customerIdentifier
  };
};
